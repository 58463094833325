<template>
  <div class="py-3 d-flex justify-content-center"
       style="background-color: #f2f2f2;    border-bottom: #428bca 10px solid;">
    <div class="w1180 bg-white p-4 my-4">
      <HouseHeader :c_title="info.title" :c_date="info.date"/>
      <img :src="getImg(item)" alt="" class="w-100" v-for="(item) in 47" :key="item">
      <HouseFooter class="mt-5" :next="next" :prev="prev"/>
    </div>
  </div>

</template>

<script>
import house from "@/house";
import HouseHeader from "@/components/House/HouseHeader";
import HouseFooter from "@/components/House/HouseFooter";

export default {
  name: "house3",
  components: {HouseHeader, HouseFooter},
  data() {
    return {
      id: 3,
      info: {},
      next: null,
      prev: null
    }
  }, mounted() {
    let id = this.id
    this.info = house.find(item => item.id === id)
    this.next = house.find(item => item.id === (id + 1))
    this.prev = house.find(item => item.id === (id - 1))
  },
  methods: {
    getImg(id) {
      return require('@/assets/house/3/' + id + '.jpg')
    }
  }
}
</script>

<style scoped>
.aligncenter {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

a {
  color: #428bca;
  text-decoration: none;
}
</style>